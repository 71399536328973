import React, { FC } from 'react'
import apiKeys from 'src/components/cms-page/api-keys'
import { IImage } from 'src/components/parallax-image'
import { theme } from 'src/../tailwind.config'

export interface IImageBlock {
  apiKey: apiKeys.image
  id: string
  mobileImage: IImage
  desktopImage: IImage
  isBottomSeparatorLine: boolean
}

interface IImageProps {
  contentBlock: IImageBlock
}

const Image: FC<IImageProps> = ({ contentBlock }) => (
  <div className="bg-white">
    <div className="md:container">
      <picture>
        <source srcSet={contentBlock.desktopImage.url} media={`(min-width: ${theme.extend.screens.md})`} />
      <img
        src={contentBlock.mobileImage.url}
        alt={contentBlock.mobileImage.alt}
        className={`${
          contentBlock.isBottomSeparatorLine
            ? 'border-grayBlue border-b mb-12'
            : ''
        }`}
      />
      </picture>
    </div>
  </div>
)

export default Image
