import React, { FC, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import { StyledListWrapper } from 'src/components/typography/List'

export interface IQuestionAndAnswerProps {
  question: string
  answer: string
  isFirstOrLastQA: number
}

interface IStyledQuestionProps {
  isOpen: boolean
}

const StyledQuestion = styled.div<IStyledQuestionProps>`
  &.firstQA{
    border-top: 3px solid;
  }

  & h2 {
    ${tw`text-base italic text-left font-bold p-0 py-2 m-0 border-0`}
  }

  & p{
    ${tw`m-0 pr-6 text-base`}
  }

  &:after{
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.449' height='16.729' viewBox='0 0 29.449 16.729'%3E%3Cpath id='Path_3647' data-name='Path 3647' d='M4891.185,5252.6l13.722-13.722,13.723,13.722' transform='translate(-4890.182 -5236.878)' fill='none' stroke='%2301141c' stroke-miterlimit='10' stroke-width='2.835'/%3E%3C/svg%3E%0A");
    line-height: 0;
    ${({isOpen}) => isOpen && `transform: rotate(0deg);`}
  }

`

const StyledQnAContainer = styled.div`

  &:last-child {
    ${tw`mb-32`}
  }
`

const StyledAnswer = styled.div`
  ul li {
    &:before {
      top: 0.55rem;
    }
  }
`

const StyledAnswerInner = styled.div`  

& *{
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
& p:last-child {
  ${tw`mb-0`}
}
`

const QuestionAndAnswer: FC<IQuestionAndAnswerProps> = ({
  question,
  answer,
  isFirstOrLastQA
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledQnAContainer className={`bg-white ${(isFirstOrLastQA == 2 ? 'mb-8' : '')}`}>
      <div className="container">
        <div>
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="block w-full"
          >
            <CmsHtmlContent>
              <StyledQuestion 
                className={`text-left font-bold italic px-3.5 py-4 md:px-5 md:py-6 m-0 border-b border-almostBlackBlue bg-appLightGreen flex justify-between ${(isFirstOrLastQA == 1 ? 'firstQA' : '')} after:flex-none after:self-center after:transform after:rotate-180 after:transition after:duration-500`}
                isOpen={isOpen}
                dangerouslySetInnerHTML={{ __html: question }}
              />
            </CmsHtmlContent>
          </button>
          <HeightAnimation isOpen={isOpen}>
            <StyledAnswer>
              <StyledListWrapper>
                <CmsHtmlContent>
                  <StyledAnswerInner
                    className="bg-appElementBackground px-3.5 py-4 md:px-5 md:py-6"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </CmsHtmlContent>
              </StyledListWrapper>
            </StyledAnswer>
          </HeightAnimation>
        </div>
      </div>
    </StyledQnAContainer>
  )
}

export default QuestionAndAnswer
