import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import SimpleLabeledSwitch from 'src/components/ui/SimpleLabeledSwitch'
import FullScreenIcon from 'src/images/icons/full-screen.svg'
import filedOfPlayImages from 'src/components/laws/interactive-picture/field-of-play/images'
import YellowCloseButton from 'src/images/icons/yellow-close.svg'
import { theme } from 'src/../tailwind.config'
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'
import PinchZoom from 'pinch-zoom-js'

interface IFielfOfPlayProps {}
/*
const StyledInteractivePicture = styled.div`
  img {
    margin: 0;
  }
`
*/
const StyledCloseFullScreen = styled.button`
  transform: translateY(-50%);
`

const headerHeight = '40px'

const imageMargin = '2.5vh'

const StyledFullScreenSvg = styled.img`
  max-height: calc(100vh - (2 * ${imageMargin}) - ${headerHeight});
  margin-top: ${imageMargin} !important;
`

const FielfOfPlay: FC<IFielfOfPlayProps> = () => {
  const [isMetric, setIsMetric] = useState(true)
  const [isNational, setIsNational] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isMobile, setIsMobile] = useState(true)
  const lngContext = React.useContext(I18nextContext)
  const { t } = useTranslation('laws')

  const handleOnClick = () => {
    setIsFullScreen(!isFullScreen)
  }

  const checkIfIsMobile = () => {
    const isMobileStatus =
      window.innerWidth < parseInt(theme.extend.screens.md, 10)
    setIsMobile(isMobileStatus)

    return isMobileStatus
  }

  const makeZoomablePicture = () => {
    if (!checkIfIsMobile()) {
      return false
    }

    const img = document.getElementById('interactive-picture')
    const imgContainer = document.getElementById('img-container')

    imgContainer.style.height = `${img.clientHeight}px`

    return new PinchZoom(img, {
      draggableUnzoomed: false,
      minZoom: 1,
    })
  }

  const makeZoomablePictureWhenLoaded = () => {
    const img = document.getElementById(
      'interactive-picture'
    ) as HTMLImageElement

    if (img.complete) {
      makeZoomablePicture()
      return
    }

    const makeZoomablePictureOnce = () => {
      if (img.offsetHeight > 0) {
        makeZoomablePicture()
        img.removeEventListener('load', makeZoomablePictureOnce)
      }
    }

    img.addEventListener('load', makeZoomablePictureOnce)
  }

  useEffect(() => {
    checkIfIsMobile()
    window.addEventListener('resize', checkIfIsMobile)

    makeZoomablePictureWhenLoaded()

    return () => {
      window.removeEventListener('resize', checkIfIsMobile)
    }
  }, [])

  const DynamicallySelectedImage = () =>
    filedOfPlayImages.find(
      (image) =>
        image.isMetric === isMetric &&
        image.isMobile === isMobile &&
        image.isNational === isNational &&
        image.language === lngContext.language
    ).image

  const isMetricButton = () => (
    <SimpleLabeledSwitch
      leftLabel={t('Meters')}
      rightLabel={t('Yards')}
      isEnabled={!isMetric}
      className="md:mr-16 px-4 py-1.5 w-full lg:w-auto lg:py-0 lg:px-0"
      onChange={() => setIsMetric(!isMetric)}
      isGrayBackground
      noPadding
    />
  )

  const isNationalButton = (fullScreen: boolean) => (
    <SimpleLabeledSwitch
      leftLabel={t('National')}
      rightLabel={t('International')}
      isEnabled={!isNational}
      className={`py-1.5 lg:pt-0 border-deepDarkBlue md:border-none px-4 w-full lg:w-auto lg:py-0 lg:px-0 ${
        fullScreen ? '' : 'border-t-2'
      }`}
      onChange={() => setIsNational(!isNational)}
      isGrayBackground
      noPadding
    />
  )

  const Buttons = (fullScreen: boolean) => (
    <div className="flex space-between flex-wrap items-start md:items-center justify-center flex-col md:flex-row w-full">
      {isMetricButton()}
      {isNationalButton(fullScreen)}
    </div>
  )

  const fullScreenInteractiveButtons = () => (
    <div className="flex flex-col items-center md:flex-row justify-center bg-almostBlackBlue text-appGreen font-bold tracking-wider uppercase relative py-1.5">
      {Buttons(true)}
      <StyledCloseFullScreen
        onClick={handleOnClick}
        type="button"
        className="absolute right-3 top-1/2 w-5"
      >
        <img
          src={YellowCloseButton}
          alt="Escape full screen"
        />
      </StyledCloseFullScreen>
    </div>
  )

  const interactiveButtons = () => (
    <div className="flex flex-col items-start md:items-center md:flex-row justify-center md:justify-between bg-almostBlackBlue text-appGreen font-bold tracking-wider uppercase md:py-1.5">
      {Buttons(false)}
      <button
        onClick={handleOnClick}
        type="button"
        className="hidden md:block mt-2.5 md:mt-0 mx-4"
      >
        <img className='!my-0' src={FullScreenIcon} alt="Full screen" />
      </button>
    </div>
  )

  const InteractiveFieldOfPlay = () => (
    <>
      <div className="w-full max-h-full">
        {interactiveButtons()}
        <div id="img-container">
          <img
            src={DynamicallySelectedImage()}
            id="interactive-picture"
            className="w-full !my-0"
            alt="The field of play"
          />
        </div>
      </div>
    </>
  )

  const InteractiveFullScreenFieldOfPlay = () => (
    <>
      <div className="inline-block w-full max-h-full bg-appGreyMedium">
        {fullScreenInteractiveButtons()}
        <StyledFullScreenSvg
          src={DynamicallySelectedImage()}
          alt="The field of play"
        />
      </div>
    </>
  )

  return (
    <div className="my-8">
      {InteractiveFieldOfPlay()}
      <div
        className={`fixed inset-0 bg-navyBlueGray z-30 ${
          isFullScreen ? 'visible' : 'hidden'
        }`}
      >
        {InteractiveFullScreenFieldOfPlay()}
      </div>
    </div>
  )
}

export default FielfOfPlay
