import React, { FC } from 'react'

export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

interface ISemiRoundedButtonProps {
  onClick?: () => void
  className?: string
  isPlain?: boolean
  size?: ButtonSizes
}

const SemiRoundedButton: FC<ISemiRoundedButtonProps> = ({
  onClick,
  className,
  children,
  isPlain,
  size,
}) => {
  const buttonMainStyles = `
    inline-block bg-almostBlackBlue text-white font-bold rounded-tl-lg
    rounded-br-lg hover:bg-brightYellow hover:text-deepDarkBlue transition duration-500 ${
      className ? `${className}` : ''
    }`

  const getButtonPadding = () => {
    switch (size) {
      case ButtonSizes.small:
        return 'px-3 capitalize'
      case ButtonSizes.medium:
        return 'px-3 text-xl uppercase'
      default:
        return 'px-3'
    }
  }

  const buttonPadding = `${getButtonPadding()}`

  const buttonStyles = `${buttonMainStyles} ${buttonPadding}`

  if (isPlain) {
    return <span className={buttonStyles}>{children}</span>
  }

  return (
    <button type="button" className={buttonStyles} onClick={onClick}>
      {children}
    </button>
  )
}
SemiRoundedButton.defaultProps = {
  size: ButtonSizes.small,
}

export default SemiRoundedButton
