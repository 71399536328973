import React, { FC } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const GreenHeaderStyled = styled.span`
  ${tw`inline px-2`}
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  background: 
    url("/images/footballrulesapp/underline-start.svg"),
    url("/images/footballrulesapp/underline-end.svg"),
    url("/images/footballrulesapp/underline-middle.svg");          
  background-repeat: no-repeat;
  background-position:
    bottom .2em left,
    bottom .2em right,
    bottom .2em left .4em;
  background-size:
    .7em .7em,
    .7em .7em,
    calc(100% - 1em) .7em;
  `

export interface IRulesGreenHeader {
  greenHeader: string
}

const GreenHeader: FC<IRulesGreenHeader> = ({greenHeader}) => (
  <div className="bg-white mb-6">
    <div className="container">
      <h2 className="-ml-2">
        <GreenHeaderStyled className='text-2xl sm:text-2.5xl tracking-wide 
          leading-none text-almostBlackBlue font-roag font-black italic
          mt-10 md:my-12 pb-1 relative z-10'
        >{greenHeader}</GreenHeaderStyled>
      </h2>
    </div>
  </div>
)

export default GreenHeader