import React, { FC } from 'react'
import { IOrganization } from 'src/components/laws/ParticipatingCompetitions'

interface IOrganizationsProps {
  organizations: IOrganization[]
  selectedOrganiztionIndex: number
  selectOrganization: (index: number) => void
}

const Organizations: FC<IOrganizationsProps> = ({
  organizations,
  selectOrganization,
  selectedOrganiztionIndex,
}) => {
  const organizationLogo = (organization, index) => {
    const isSelected = selectedOrganiztionIndex === index
    const selectedClasses = 'md:border-grayBlue bg-paleBlue md:bg-transparent'

    return (
      <button
        key={organization.name}
        type="button"
        onClick={() => selectOrganization(index)}
        className={`p-3 md:p-4 flex items-center justify-center border-b-2 border-transparent rounded-br-lg rounded-tl-lg md:rounded-none
          ${isSelected ? selectedClasses : ''}
        `}
      >
        <img
          src={organization.logoUrl}
          alt={organization.name}
          className="w-16"
        />
      </button>
    )
  }

  return (
    <div className="grid grid-cols-4 md:grid-cols-7 border-grayBlue border-b-2 mb-12 mt-4 pb-4 md:pb-0">
      {organizations.map((organization, index) =>
        organizationLogo(organization, index)
      )}
    </div>
  )
}

export default Organizations
