import React, { FC, useRef, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'src/../tailwind.config'
import { IImage } from 'src/components/parallax-image'
import tw from 'twin.macro'

const DesktopFooterImageBg  = styled.div<IFooterBgProp>`
  max-height: 28rem;
  background-image: url('${({ bgPic }) => bgPic}');
`
const TextWrapper  = styled.div`
    max-width: ${parseInt(theme.extend.screens.xl)+200}px;
    ${tw`w-full inline-flex flex-col m-auto`}

    & span{
        font-size: 17.5rem;
        font-size: clamp(1rem, 19vw, 17.5rem);
        line-height: .8;
        max-height: 14rem;
        height: 15.15vw;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: ${theme.extend.colors.appGreen};
        -webkit-text-fill-color: transparent;
        ${tw`text-appGreen font-black italic inline-block uppercase whitespace-nowrap`}
    
        &.line1{
            transform: translateY(-5vw);
            transform: translateY(max(-5vw,-4.6rem));
            ${tw`self-start`}

            &.singleLine{
                ${tw`self-center`}
            }
        }
        &.line2{
            transform: translateY(1.5vw);
            transform: translateY(min(1.5vw, 1.5rem));
            ${tw`self-end content-end`}
        }
      }
`

export interface IDesktopFooterImage {
  line1: string;
  line2: string;
  image: IImage;
}

interface IDesktopFooterImageWrapperProps {
  desktopFooterImageData: IDesktopFooterImage;
}
interface IFooterBgProp {
  bgPic: string
}


const DesktopFooterImage: FC<IDesktopFooterImageWrapperProps> = ({
    desktopFooterImageData,
}) => {

    let isSingle = desktopFooterImageData.line2 == '';
    
    return (
      <div className="hidden bg-white my-12 py-12 overflow-hidden md:block lg:my-16 xl:my-24">
        <DesktopFooterImageBg
          className="bg-almostBlackBlue bg-cover bg-center text-center"
          bgPic={desktopFooterImageData?.image?.url}
        >
          <TextWrapper>
            <span className={"line1 " + (isSingle ? "singleLine" : "")}>
              {desktopFooterImageData.line1}
            </span>
            <span className="line2">
              {isSingle ? "" : desktopFooterImageData.line2}
            </span>
          </TextWrapper>
        </DesktopFooterImageBg>
      </div>
    );}

export default DesktopFooterImage
