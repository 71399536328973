import React, { FC } from 'react'
import ThinHeader from 'src/components/layout/ThinHeader'
import styled from 'styled-components'
import tw from 'twin.macro'
import apiKeys from 'src/components/cms-page/api-keys'
import { IImage } from 'src/components/parallax-image'
import Subtitle from 'src/components/typography/Subtitle'

export interface IScrollableImage {
  id: string
  apiKey: apiKeys.scrollableImage
  image: IImage
  isBottomMarginDisabled: boolean
  thinHeaderText?: string
  subtitleHeader?: string
}

interface IScrollableImageProps {
  contentBlock: IScrollableImage
}

const StyledShadow = styled.div`
  box-shadow: 0px 0px 40px 10px black;
  left: 100%;
`

const DimensionsImg = styled.img<{ originalWidth: number }>`
  width: ${(props) => props.originalWidth}px;
  ${tw`md:w-full max-w-none`}
`

const ScrollableImage: FC<IScrollableImageProps> = ({ contentBlock }) => {
  if (!contentBlock.image) {
    return null
  }
  const {
    thinHeaderText,
    subtitleHeader,
    image: { url, alt },
  } = contentBlock

  const getHeader = () => (
    <ThinHeader className="text-center">
      <span>{thinHeaderText}</span>
    </ThinHeader>
  )

  const getSubtitleHeader = () => <Subtitle noMargin>{subtitleHeader}</Subtitle>

  return (
    <div className="container">
      <div
        className={`mt-12 ${
          contentBlock.isBottomMarginDisabled ? '' : 'mb-24'
        }`}
      >
        {thinHeaderText ? getHeader() : <></>}
        {subtitleHeader ? getSubtitleHeader() : <></>}
        <div className="relative overflow-hidden">
          <div className="overflow-x-auto hide-scrollbar relative">
            <DimensionsImg src={url} alt={alt} originalWidth={900} />
          </div>
          <StyledShadow className="absolute right-0 top-0 bottom-0 w-0.5 z-10 md:hidden" />
        </div>
      </div>
    </div>
  )
}

export default ScrollableImage
