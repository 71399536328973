import EnInternationalMetricDesktop from 'src/images/SVG/field-of-play/en/international-metric-desktop.svg'
import EnInternationalMetricMobile from 'src/images/SVG/field-of-play/en/international-metric-mobile.svg'
import EnInternationalYardsMobile from 'src/images/SVG/field-of-play/en/international-yards-mobile.svg'
import EnInternationalYardsDesktop from 'src/images/SVG/field-of-play/en/international-yards-desktop.svg'
import EnNationalMetricDesktop from 'src/images/SVG/field-of-play/en/national-metric-desktop.svg'
import EnNationalMetricMobile from 'src/images/SVG/field-of-play/en/national-metric-mobile.svg'
import EnNationalYardsDesktop from 'src/images/SVG/field-of-play/en/national-yards-desktop.svg'
import EnNationalYardsMobile from 'src/images/SVG/field-of-play/en/national-yards-mobile.svg'

interface InteractiveImage {
  isNational: boolean
  isMetric: boolean
  isMobile: boolean
  image: string
  language: string
}

const images: Array<InteractiveImage> = [
  {
    isNational: false,
    isMetric: true,
    isMobile: false,
    image: EnInternationalMetricDesktop,
    language: 'en',
  },
  {
    isNational: false,
    isMetric: true,
    isMobile: true,
    image: EnInternationalMetricMobile,
    language: 'en',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: true,
    image: EnInternationalYardsMobile,
    language: 'en',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: false,
    image: EnInternationalYardsDesktop,
    language: 'en',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: false,
    image: EnNationalMetricDesktop,
    language: 'en',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: true,
    image: EnNationalMetricMobile,
    language: 'en',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: false,
    image: EnNationalYardsDesktop,
    language: 'en',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: true,
    image: EnNationalYardsMobile,
    language: 'en',
  },
]

export default images
