import FrInternationalMetricDesktop from 'src/images/SVG/field-of-play/fr/international-metric-desktop.svg'
import FrInternationalMetricMobile from 'src/images/SVG/field-of-play/fr/international-metric-mobile.svg'
import FrInternationalYardsMobile from 'src/images/SVG/field-of-play/fr/international-yards-mobile.svg'
import FrInternationalYardsDesktop from 'src/images/SVG/field-of-play/fr/international-yards-desktop.svg'
import FrNationalMetricDesktop from 'src/images/SVG/field-of-play/fr/national-metric-desktop.svg'
import FrNationalMetricMobile from 'src/images/SVG/field-of-play/fr/national-metric-mobile.svg'
import FrNationalYardsDesktop from 'src/images/SVG/field-of-play/fr/national-yards-desktop.svg'
import FrNationalYardsMobile from 'src/images/SVG/field-of-play/fr/national-yards-mobile.svg'

interface InteractiveImage {
  isNational: boolean
  isMetric: boolean
  isMobile: boolean
  image: string
  language: string
}

const images: Array<InteractiveImage> = [
  {
    isNational: false,
    isMetric: true,
    isMobile: false,
    image: FrInternationalMetricDesktop,
    language: 'fr',
  },
  {
    isNational: false,
    isMetric: true,
    isMobile: true,
    image: FrInternationalMetricMobile,
    language: 'fr',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: true,
    image: FrInternationalYardsMobile,
    language: 'fr',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: false,
    image: FrInternationalYardsDesktop,
    language: 'fr',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: false,
    image: FrNationalMetricDesktop,
    language: 'fr',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: true,
    image: FrNationalMetricMobile,
    language: 'fr',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: false,
    image: FrNationalYardsDesktop,
    language: 'fr',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: true,
    image: FrNationalYardsMobile,
    language: 'fr',
  },
]

export default images
