import DeInternationalMetricDesktop from 'src/images/SVG/field-of-play/de/international-metric-desktop.svg'
import DeInternationalMetricMobile from 'src/images/SVG/field-of-play/de/international-metric-mobile.svg'
import DeInternationalYardsMobile from 'src/images/SVG/field-of-play/de/international-yards-mobile.svg'
import DeInternationalYardsDesktop from 'src/images/SVG/field-of-play/de/international-yards-desktop.svg'
import DeNationalMetricDesktop from 'src/images/SVG/field-of-play/de/national-metric-desktop.svg'
import DeNationalMetricMobile from 'src/images/SVG/field-of-play/de/national-metric-mobile.svg'
import DeNationalYardsDesktop from 'src/images/SVG/field-of-play/de/national-yards-desktop.svg'
import DeNationalYardsMobile from 'src/images/SVG/field-of-play/de/national-yards-mobile.svg'

interface InteractiveImage {
  isNational: boolean
  isMetric: boolean
  isMobile: boolean
  image: string
  language: string
}

const images: Array<InteractiveImage> = [
  {
    isNational: false,
    isMetric: true,
    isMobile: false,
    image: DeInternationalMetricDesktop,
    language: 'de',
  },
  {
    isNational: false,
    isMetric: true,
    isMobile: true,
    image: DeInternationalMetricMobile,
    language: 'de',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: true,
    image: DeInternationalYardsMobile,
    language: 'de',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: false,
    image: DeInternationalYardsDesktop,
    language: 'de',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: false,
    image: DeNationalMetricDesktop,
    language: 'de',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: true,
    image: DeNationalMetricMobile,
    language: 'de',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: false,
    image: DeNationalYardsDesktop,
    language: 'de',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: true,
    image: DeNationalYardsMobile,
    language: 'de',
  },
]

export default images
