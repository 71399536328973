enum apiKeys {
  newsHtml = 'news_html',
  gatsbyLink = 'gatsby_link',
  highlight = 'highlight',
  transitionImage = 'transition_image',
  scrollableImage = 'scrollable_image',
  trialProtocol = 'protocol',
  participatingCompetition = 'participating_competition',
  image = 'image',
  questionAndAnswer = 'question_and_answer',
  rulesGreenHeader = 'rules_green_header',
  greenHighlightedText = 'green_highlighted_text',
}

export default apiKeys
