import React, { FC } from 'react'
import ParallaxImage, { IImage } from 'src/components/parallax-image'
import TrialProtocols, {
  ITrialProtocols,
} from 'src/components/laws/TrialProtocols'
import ParticipatingCompetitions from 'src/components/laws/ParticipatingCompetitions'
import NewsHtml, {
  IContentHtmlBlock,
} from 'src/components/cms-page/blocks/NewsHtml'
import GatsbyLink, {
  IContentLinkBlock,
} from 'src/components/cms-page/blocks/GatsbyLink'
import apiKeys from 'src/components/cms-page/api-keys'
import Highlight, {
  IHighlightBlock,
} from 'src/components/cms-page/blocks/Highlight'
import ScrollableImage, {
  IScrollableImage,
} from 'src/components/cms-page/blocks/ScrollableImage'
import Image, { IImageBlock } from 'src/components/cms-page/blocks/Image'
import GreenHeader from 'src/components/cms-page/blocks/GreenHeader'
import QuestionAndAnswer from 'src/components/cms-page/blocks/QuestionAndAnswer'
import GreenHighlightedText from './blocks/GreenHighlightedText'

interface IParticipatingCompetition {
  id: string
  apiKey: apiKeys.participatingCompetition
  competitionsStructure: string
}

export interface IContentTransitionImageBlock {
  apiKey: apiKeys.transitionImage
  id: string
  desktopImage: IImage
  mobileImage: IImage
}

interface IQuestionAndAnswerBlock {
  apiKey: apiKeys.questionAndAnswer
  id: string
  answer: string
  question: string
}

interface IRulesGreenHeader {
  apiKey: apiKeys.rulesGreenHeader
  id: string
  greenHeader: string
}

interface IGreenHighlightedText {
  apiKey: apiKeys.greenHighlightedText
  id: string
  text: string
}

export type IContentBlock =
  | IContentHtmlBlock
  | IContentLinkBlock
  | IContentTransitionImageBlock
  | IHighlightBlock
  | IScrollableImage
  | ITrialProtocols
  | IParticipatingCompetition
  | IImageBlock
  | IQuestionAndAnswerBlock
  | IRulesGreenHeader
  | IGreenHighlightedText

interface IContentBlockSwitcherProps {
  contentBlockItem: IContentBlock
  extraMarginTop: boolean
  isFirstOrLastQA: number
}



const ContentBlockSwitcher: FC<IContentBlockSwitcherProps> = ({
  contentBlockItem,
  extraMarginTop,
  isFirstOrLastQA
}) => {

  const switchContentBlockToComponent = (
    contentBlock: IContentBlock
  ): JSX.Element => {
    switch (contentBlock.apiKey) {
      /**
       * TODO: It's no longer only only the news html.
       * Would be nice to rename it to more generic name like cms_html or other.
       */
      case apiKeys.newsHtml:
        return (
          <NewsHtml
            contentBlockItem={contentBlock}
            extraMarginTop={extraMarginTop}
          />
        )
      case apiKeys.gatsbyLink:
        return <GatsbyLink contentBlockItem={contentBlock} />
      case apiKeys.transitionImage:
        return (
          <ParallaxImage
            desktopImage={contentBlock.desktopImage}
            mobileImage={contentBlock.mobileImage}
            id={contentBlock.id}
          />
        )
      case apiKeys.highlight:
        return <Highlight contentBlock={contentBlock} />
      case apiKeys.scrollableImage:
        return <ScrollableImage contentBlock={contentBlock} />
      case apiKeys.trialProtocol:
        return <TrialProtocols contentBlock={contentBlock} />
      case apiKeys.participatingCompetition: {
        const organizations = JSON.parse(contentBlock.competitionsStructure)

        return <ParticipatingCompetitions organizations={organizations} />
      }
      case apiKeys.image:
        return <Image contentBlock={contentBlock} />

      case apiKeys.rulesGreenHeader:
            return <GreenHeader greenHeader={contentBlock.greenHeader} />
            
      case apiKeys.greenHighlightedText:
        return <GreenHighlightedText text={contentBlock.text} />

      case apiKeys.questionAndAnswer:
        return (
          <QuestionAndAnswer
            isFirstOrLastQA={isFirstOrLastQA}
            question={contentBlock.question}
            answer={contentBlock.answer}
          />
        )
      default:
        return <div className="block-was-not-found" />
    }
  }

  return <>{switchContentBlockToComponent(contentBlockItem)}</>

}

export default ContentBlockSwitcher
