import React, { FC } from 'react'
import TextHightlight from 'src/components/typography/TextHightlight'
import tw from 'twin.macro'
import styled from 'styled-components'

interface IHighlightedSubtitleProps {
  children: string | JSX.Element | JSX.Element[]
  isSticked?: boolean
  highlightColor?: string
  className?: string
}

const HighlightedSubtitleStyles = tw`
    text-left font-thin text-deepDarkBlue tracking-wider
    text-3xl mb-5 sm:text-4xl md:text-5xl
`

export const PlainSubtitle = styled.h3`
  ${HighlightedSubtitleStyles}
`

const DynamicallyStyledHighlightedSubtitle = styled.h3`
  ${HighlightedSubtitleStyles}
`

const HighlightedSubtitle: FC<IHighlightedSubtitleProps> = ({
  children,
  highlightColor,
  className,
}) => (
  <DynamicallyStyledHighlightedSubtitle
    className={`${DynamicallyStyledHighlightedSubtitle} ${className}`}
  >
    <TextHightlight highlightColor={highlightColor}>
      {typeof children === 'string' ? <div>{children}</div> : children}
    </TextHightlight>
  </DynamicallyStyledHighlightedSubtitle>
)

export default HighlightedSubtitle
