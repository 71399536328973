import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitleWrapper, { IPageTitleData } from 'src/components/typography/page-title'
import DesktopFooterImage, { IDesktopFooterImage } from 'src/components/layout/DesktopFooterImage'
import Section from 'src/components/typography/Section'
import { pagePadding } from 'src/components/layout/ContentPageContainer'
import ContentBlockSwitcher, {
  IContentBlock,
} from 'src/components/cms-page/ContentBlockSwitcher'
import styled from 'styled-components'
import tw from 'twin.macro'
import MobileFooter from "src/components/layout/MobileFooter";

interface ISeo {
  title: string
  description: string
  image: {
    url: string
  };
  twitterCard: string
}

interface ICmsPageProps {
  pageContext: {
    pageData: IPageTitleData & {
      contentBlocks: IContentBlock[];
      desktopFooterImage: IDesktopFooterImage[];
    };
    seo: ISeo;
    canonicalUrl?: string;
  };
}

const StyledSection = styled(Section)`
  ${tw`mb-0`}
`

const marginAfterSections = <div className="h-24 bg-white" />

const isTwoHtmlBlocksInRow = (
  blocks: Array<IContentBlock>,
  currentBlockIndex: number
) => {
  if (blocks[currentBlockIndex].apiKey === 'news_html') {
    const isPreviousSectionHtmlBlock =
      blocks[currentBlockIndex - 1] &&
      blocks[currentBlockIndex - 1].apiKey === 'news_html'

    return isPreviousSectionHtmlBlock
  }

  return false
}

const isFirstOrLastQandA = (
  blocks: Array<IContentBlock>,
  currentBlockIndex: number
) => {
  if (blocks[currentBlockIndex].apiKey === 'question_and_answer') {
    
    if(!blocks[currentBlockIndex + 1] || blocks[currentBlockIndex + 1].apiKey !== 'question_and_answer')
      return 2
    if(
      blocks[currentBlockIndex - 1] &&
      blocks[currentBlockIndex - 1].apiKey === 'question_and_answer'
      )
      return 0
    
  } 

  return 1
}

const GenericCmsPage = ({
  pageContext: { pageData, seo, canonicalUrl },
}: ICmsPageProps) => {
  const mappedSectionsToComponents = () =>
    pageData.contentBlocks.map((contentBlock, index) => {
      const isMarginTop = isTwoHtmlBlocksInRow(pageData.contentBlocks, index);
      const isFirstOrLastQA = isFirstOrLastQandA(pageData.contentBlocks, index);
      return (
        <ContentBlockSwitcher
          isFirstOrLastQA={isFirstOrLastQA}
          extraMarginTop={isMarginTop}
          key={contentBlock.id}
          contentBlockItem={contentBlock}
        />
      );
    });
  
  const pageTitleData = {
    pageTitleLine1Margin: pageData.pageTitleLine1Margin,
    pageTitleLine2Margin: pageData.pageTitleLine2Margin,
    mobilePageTitleLine1Margin: pageData.mobilePageTitleLine1Margin,
    mobilePageTitleLine2Margin: pageData.mobilePageTitleLine2Margin,
    pageTitleLine1: pageData.pageTitleLine1,
    pageTitleLine2: pageData.pageTitleLine2,
    mobilePageTitleLine1: pageData.mobilePageTitleLine1,
    mobilePageTitleLine2: pageData.mobilePageTitleLine2,

    mobileHeaderImage: pageData.mobileHeaderImage
  }

  let desktopFooterImageData = null;

  const addDesktopFooterImage = () => {
    if(pageData.desktopFooterImage.length > 0)
    {
      desktopFooterImageData = {
      line1: pageData.desktopFooterImage[0].line1,
      line2: pageData.desktopFooterImage[0].line2,
      image: pageData.desktopFooterImage[0].image
      };

      return (
        <DesktopFooterImage desktopFooterImageData={desktopFooterImageData} />
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{(seo && seo.title) || (pageTitleData.pageTitleLine1 + (pageTitleData.pageTitleLine2 && ' ' + pageTitleData.pageTitleLine2))}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
        <meta name="description" content={(seo && seo.description) || (pageTitleData.pageTitleLine1 + (pageTitleData.pageTitleLine2 && ' ' + pageTitleData.pageTitleLine2))} />
      </Helmet>
      <Layout>
        <div className={`min-h-screen text-deepDarkBlue mb-12 ${pagePadding}`}>
          <StyledSection>
            <>
              <PageTitleWrapper pageTitleData={pageTitleData} />
              {mappedSectionsToComponents()}
              {addDesktopFooterImage()}
            </>
          </StyledSection>
        </div>
        <MobileFooter/>
      </Layout>
      
    </>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default GenericCmsPage;
