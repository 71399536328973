import React, { FC, useEffect, useRef, useContext } from "react";
import LanguageSwitcher from "src/components/language/LanguageSwitcher";
import JoinUsIcons from "src/components/icons/JoinUsIcons";
import {
  useTranslation,
  I18nextContext,
  Link,
} from "gatsby-plugin-react-i18next";


const MobileFooter: FC = () => {

  return (
    <>
      <div className='font-black italic uppercase leading-tight px-6 py-12 sm:py-16 bg-appLightGreen flex items-center justify-center lg:hidden'>
        <a
          href='https://www.theifab.com/laws/latest/the-field-of-play/?side-menu-category=laws-of-the-game&side-menu-sub-category=laws-1-17&side-menu-open=true&side-menu-open=true' target="_blank" className="flex items-center group">
            <span className="mr-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="52" height="40" viewBox="0 0 86.402 65.68">
              <g id="Group_1474" data-name="Group 1474" transform="translate(-5750.87 -7415.554)">
                <path className="group-hover:fill-white transition duration-300" id="Path_3327" data-name="Path 3327" d="M5750.87,7481.234h52.282a16.166,16.166,0,0,0,12.6-6.045l21.515-26.795-22.052-27.464a14.38,14.38,0,0,0-11.211-5.376h-35.635a7.176,7.176,0,0,0-7.069,5.941Z" fill="#01141c"/>
              </g>
              <path className="group-hover:fill-almostBlackBlue transition duration-300" id="Path_3328" data-name="Path 3328" d="M5779.254,7458.411h11.67L5789,7469.264h-11.669Zm4.727-29.99h12.777l-6.185,26.781h-9.86Z" transform="translate(-5750.87 -7415.554)" fill="#fff"/>
            </svg>
            </span>
            <span className="max-w-[14.5rem] md:max-w-none">See the complete laws of the game at theIFAB.com</span>
        </a>
      </div>
    </>
  );
};

export default MobileFooter;
