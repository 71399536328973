import EsInternationalMetricDesktop from 'src/images/SVG/field-of-play/es/international-metric-desktop.svg'
import EsInternationalMetricMobile from 'src/images/SVG/field-of-play/es/international-metric-mobile.svg'
import EsInternationalYardsMobile from 'src/images/SVG/field-of-play/es/international-yards-mobile.svg'
import EsInternationalYardsDesktop from 'src/images/SVG/field-of-play/es/international-yards-desktop.svg'
import EsNationalMetricDesktop from 'src/images/SVG/field-of-play/es/national-metric-desktop.svg'
import EsNationalMetricMobile from 'src/images/SVG/field-of-play/es/national-metric-mobile.svg'
import EsNationalYardsDesktop from 'src/images/SVG/field-of-play/es/national-yards-desktop.svg'
import EsNationalYardsMobile from 'src/images/SVG/field-of-play/es/national-yards-mobile.svg'

interface InteractiveImage {
  isNational: boolean
  isMetric: boolean
  isMobile: boolean
  image: string
  language: string
}

const images: Array<InteractiveImage> = [
  {
    isNational: false,
    isMetric: true,
    isMobile: false,
    image: EsInternationalMetricDesktop,
    language: 'es',
  },
  {
    isNational: false,
    isMetric: true,
    isMobile: true,
    image: EsInternationalMetricMobile,
    language: 'es',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: true,
    image: EsInternationalYardsMobile,
    language: 'es',
  },
  {
    isNational: false,
    isMetric: false,
    isMobile: false,
    image: EsInternationalYardsDesktop,
    language: 'es',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: false,
    image: EsNationalMetricDesktop,
    language: 'es',
  },
  {
    isNational: true,
    isMetric: true,
    isMobile: true,
    image: EsNationalMetricMobile,
    language: 'es',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: false,
    image: EsNationalYardsDesktop,
    language: 'es',
  },
  {
    isNational: true,
    isMetric: false,
    isMobile: true,
    image: EsNationalYardsMobile,
    language: 'es',
  },
]

export default images
