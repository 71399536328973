import React, { FC } from 'react'
import { CmsHtmlContentGrayUnderline } from 'src/components/typography/CmsHtmlContentFormats'
import { StyledListWrapper } from 'src/components/typography/List'
import apiKeys from 'src/components/cms-page/api-keys'
import { Markup, Node } from 'interweave'

import InteractiveFieldOfPlay from 'src/components/laws/interactive-picture/field-of-play'

export interface IContentHtmlBlock {
  apiKey: apiKeys.newsHtml
  id: string
  htmlContent: string
}

interface INewsHtmlProps {
  contentBlockItem: IContentHtmlBlock
  extraMarginTop: boolean
}

const transform = (node: HTMLElement, children: Node[]): React.ReactNode => {

  if(
    node.tagName.toLowerCase() == 'p' && 
    node.children.length > 0 && 
    node.children[0].tagName.toLowerCase() == 'thefieldinteractivepicture'
    )
  {
    return <InteractiveFieldOfPlay />
  }
  
  return undefined
}

const NewsHtml: FC<INewsHtmlProps> = ({ contentBlockItem, extraMarginTop }) => (
  <div className="bg-white">
    <div className={`container ${extraMarginTop ? 'mt-14' : ''}`}>
      <StyledListWrapper>
        <CmsHtmlContentGrayUnderline
          //dangerouslySetInnerHTML={{ __html: contentBlockItem.htmlContent }}
         >
          <Markup
            content={contentBlockItem.htmlContent}
            transform={transform}
            allowElements
            allowAttributes
          />
          </CmsHtmlContentGrayUnderline>
      </StyledListWrapper>
    </div>
  </div>
)

export default NewsHtml
