import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from 'src/../tailwind.config'
import tw from 'twin.macro'


const GreenHighlightedTextStyled = styled.div`

&:before{
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='86' height='64' viewBox='0 0 86 64'%3E%3Cg id='Group_1126' data-name='Group 1126' transform='translate(-443.194 -5734.466)'%3E%3Cpath id='Path_4140' data-name='Path 4140' d='M443.194,5798.466h52.039a16.219,16.219,0,0,0,12.546-5.891l21.415-26.109L507.245,5739.7a14.431,14.431,0,0,0-11.159-5.239H460.617a7.1,7.1,0,0,0-7.036,5.791Z' fill='%2301141c'/%3E%3Cpath id='Path_4141' data-name='Path 4141' d='M488.441,5759.3c2.88,0,5.6,0,8.329,0,3.565,0,4.8,1.553,4.236,5.139a11.214,11.214,0,0,1-2.018,4.948c-1.765,2.468-3.416,5-5.1,7.52a3.5,3.5,0,0,1-3.073,1.728c-5.275-.011-10.556,0-15.831-.008-1.242,0-1.521-.36-1.3-1.635.934-5.354,1.832-10.708,2.873-16.06a6.17,6.17,0,0,1,1.54-2.851c2.942-3.059,6-6.012,9.037-8.984a4.582,4.582,0,0,1,1.38-.959c1.55-.635,2.836.178,2.5,1.725-.576,2.683-1.349,5.359-2.055,8.036C488.857,5758.3,488.667,5758.7,488.441,5759.3Zm-24.424,17.8c-.184,1.015.169,1.54,1.206,1.532.973-.007,1.945,0,2.916,0a1.513,1.513,0,0,0,1.633-1.385q1.465-8.244,2.911-16.487c.17-.97-.29-1.43-1.221-1.449q-1.284-.027-2.578,0c-1.352.024-1.784.387-2.032,1.756-.483,2.655-.941,5.309-1.408,7.963C464.97,5771.715,464.505,5774.407,464.017,5777.1Z' transform='translate(-0.17 -0.647)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
}
`

export interface IGreenHighlightedText {
  text: string
}

const GreenHighlightedText: FC<IGreenHighlightedText> = ({text}) => (
  <div className="bg-white">
    <div className="container">
      <GreenHighlightedTextStyled className='
      bg-appLightGreen 
      text-base leading-snug italic 
      pl-12 pr-6 py-4 my-8 md:px-8 md:py-6 
      relative rounded-tl-lg rounded-br-lg
      md:pl-20 md:grid md:items-center md:min-h-[6rem]
      before:-left-7 before:-top-1.5 before:block before:absolute before:transform before:scale-[.6]
      before:md:h-16 before:md:-left-6 before:md:top-1/2 before:md:-translate-y-1/2 before:md:scale-[1]'>
        {text}
      </GreenHighlightedTextStyled>
    </div>
  </div>
)

export default GreenHighlightedText