import React, { FC } from 'react'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import { IImage } from 'src/components/parallax-image'
import tw from 'twin.macro'

const tailwind = resolveConfig(tailwindConfig);

const MainHeaderWrapper  = styled.div<IMainHeaderBgProp>`
  background-image: url('${({ bgPic }) => bgPic}');
  ${tw`md:bg-none`}
`
const MainHeaderH1 = styled.h1`
  font-size: 2rem;
  font-size: clamp(2rem, 10vw, 3.5rem);
  transform: translateY(-1.72vw);
  ${tw`inline font-roag text-white text-left font-black italic uppercase leading-none m-auto`}

  @media (min-width: ${tailwind.theme.screens.sm}) {
    font-size: 3.5rem;
  }
  @media (min-width: ${tailwind.theme.screens.md}) {
    transform: translateY(0);
    ${tw`text-7xl inline-block text-almostBlackBlue mt-16 mb-8`}
  }
  @media (min-width: ${tailwind.theme.screens.xl}) {
    font-size: 5rem;
    font-size: clamp(4.5rem, 6vw, 6.25rem);
  }
`

export interface IPageTitleData {
  pageTitleLine1Margin: number;
  pageTitleLine2Margin: number;
  mobilePageTitleLine1Margin: number;
  mobilePageTitleLine2Margin: number;
  pageTitleLine1: string;
  pageTitleLine2: string;
  mobilePageTitleLine1: string;
  mobilePageTitleLine2: string;

  mobileHeaderImage: IMobileHeaderImage;
}

interface IMobileHeaderImage {
  title: string;
  content: string;
  mobileImage: IImage;
}

interface IPageTitleWrapperProps {
  pageTitleData: IPageTitleData;
}
interface IMainHeaderBgProp {
  bgPic: string
}


const PageTitleWrapper: FC<IPageTitleWrapperProps> = ({
  pageTitleData,
}) => (
  <div className='md:container mb-12'>
    <MainHeaderWrapper bgPic={pageTitleData.mobileHeaderImage[0].mobileImage.url} 
    className='flex bg-almostBlackBlue bg-center bg-cover text-center min-h-[66vw] h-[82vw] px-4 sm:px-8 md:min-h-0 md:bg-transparent md:h-auto'>
      <MainHeaderH1>
          <span className='hidden md:block' style={ pageTitleData.pageTitleLine1Margin != 0 ? {marginLeft: pageTitleData.pageTitleLine1Margin+'em'} : {} }>{pageTitleData.pageTitleLine1}</span> 
          {pageTitleData.pageTitleLine2 ? (<span className='hidden md:block' style={ pageTitleData.pageTitleLine2Margin != 0 ? {marginLeft: pageTitleData.pageTitleLine2Margin+'em'} : {} }>{pageTitleData.pageTitleLine2}</span>) : (null)}

          <span className='block w-max md:hidden' style={ pageTitleData.mobilePageTitleLine1Margin != 0 ? {marginLeft: pageTitleData.mobilePageTitleLine1Margin+'em'} : {} }>{pageTitleData.mobilePageTitleLine1}</span>
          {pageTitleData.mobilePageTitleLine2 ? (<span className='block w-max md:hidden' style={ pageTitleData.mobilePageTitleLine2Margin != 0 ? {marginLeft: pageTitleData.mobilePageTitleLine2Margin+'em'} : {} }>{pageTitleData.mobilePageTitleLine2}</span>) : (null)}
      </MainHeaderH1>
    </MainHeaderWrapper>
    {pageTitleData.mobileHeaderImage[0].content ? (
      <div className='bg-almostBlackBlue text-center md:hidden text-center pt-10 pb-12'>
          <div className='text-lg text-appGreen tracking-wide uppercase font-black italic w-2/3 m-auto mb-4'>{pageTitleData.mobileHeaderImage[0].title}</div>
          <div className='text-base text-white italic w-3/4 sm:w-2/3 m-auto'>{pageTitleData.mobileHeaderImage[0].content}</div>
      </div>
     ) : (null)}
    </div>
)


PageTitleWrapper.defaultProps = {
    // className: '',
}

export default PageTitleWrapper
