import React, { FC, useState, useEffect } from 'react'
import InternationalCompetitions from 'src/components/laws/ParticipatingCompetitions/InternationalCompetitions'
import Countries from 'src/components/laws/ParticipatingCompetitions/Countries'
import Organizations from 'src/components/laws/ParticipatingCompetitions/Organizations'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export interface ISplittedNationalCompetitions {
  ongoing: Array<string>
  completed: Array<string>
}
export interface ICountry {
  name: string
  flagImgUrl: string
  splittedNationalCompetitions: ISplittedNationalCompetitions
}

export interface IInternationalCompetition {
  name: string
  logoUrl: string
}

export interface IOrganization {
  name: string
  logoUrl: string
  internationalCompetitionsLabel: string
  countries?: Array<ICountry>
  internationalCompetitions?: Array<IInternationalCompetition>
}

interface IParticipatingCompetitionsProps {
  organizations: Array<IOrganization>
}

const ParticipatingCompetitions: FC<IParticipatingCompetitionsProps> = ({
  organizations,
}) => {
  const [selectedOrganiztionIndex, setSelectedOrganiztionIndex] = useState(null)
  const getSelectedOrganization = () =>
    selectedOrganiztionIndex !== null && organizations[selectedOrganiztionIndex]

  const { t } = useTranslation('laws')

  useEffect(() => {
    setSelectedOrganiztionIndex(0)
  }, [])

  return (
    <div className="container">
      <div className="bg-white">
        <div
          className={`${getSelectedOrganization() === null ? 'mb-40' : 'mb-14'}
          `}
        >
          <Organizations
            organizations={organizations}
            selectedOrganiztionIndex={selectedOrganiztionIndex}
            selectOrganization={(index: number) =>
              setSelectedOrganiztionIndex(index)
            }
          />
          <Countries selectedOrganization={getSelectedOrganization()} />
          <InternationalCompetitions
            selectedOrganization={getSelectedOrganization()}
          />
          {getSelectedOrganization() !== null &&
          !getSelectedOrganization().countries &&
          !getSelectedOrganization().internationalCompetitions ? (
            <p>{t('None')}</p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default ParticipatingCompetitions
