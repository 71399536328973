import React, { FC, useEffect, useRef, useState } from 'react'
import {
  IOrganization,
  ICountry,
  ISplittedNationalCompetitions,
} from 'src/components/laws/ParticipatingCompetitions'
import SectionTitle from 'src/components/typography/SectionTitle'
import styled from 'styled-components'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import CloseIcon from 'src/images/icons/close.inline.svg'
import { LazyMotion, m, domAnimation } from 'framer-motion'

const animationVariants = {
  hidden: {
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
  },
  show: {
    display: 'block',
    opacity: 1,
  },
}
interface ICountriesProps {
  selectedOrganization: IOrganization
}

const StyledNationalCompetitionsBox = styled.div`
  min-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Countries: FC<ICountriesProps> = ({ selectedOrganization }) => {
  const [
    competitionsToDisplay,
    setCompetitionsToDisplay,
  ] = useState<ISplittedNationalCompetitions>(null)
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation('laws')
  const popupRef = useRef<HTMLDivElement>(null)

  if (selectedOrganization === null) {
    return <></>
  }

  const handleCountryFlagClick = (
    splittedNationalCompetitions: ISplittedNationalCompetitions
  ) => {
    if (isVisible) {
      return false
    }
    setCompetitionsToDisplay(splittedNationalCompetitions)
    setIsVisible(true)

    return true
  }

  const CountryFlag = ({
    flagImgUrl,
    name,
    splittedNationalCompetitions,
  }: ICountry) => (
    <li className="mb-6" key={name}>
      <button
        type="button"
        className="flex flex-wrap justifyitems-center justify-center"
        onClick={() => handleCountryFlagClick(splittedNationalCompetitions)}
      >
        <img src={flagImgUrl} alt={name} className="w-12 h-12" />
        <p className="whitespace-pre text-center w-full mt-1">{name}</p>
      </button>
    </li>
  )

  const handleDocumentClick = (event) => {
    const isClickedOutside =
      popupRef.current && !popupRef.current.contains(event.target)

    if (isClickedOutside) {
      setIsVisible(null)
    }
  }

  useEffect(() => {
    if (!isVisible) {
      return () => {}
    }

    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isVisible])

  const { countries } = selectedOrganization

  if (!countries) {
    return <></>
  }

  const mappedCountriesToComponents = countries.map((country) =>
    CountryFlag(country)
  )

  const competitionsList = (competitions: Array<string>) =>
    competitions.map((competition) => (
      <li key={competition} className="text-sm">
        {competition}
      </li>
    ))

  const ongoingCompetitions = () => {
    if (!competitionsToDisplay.ongoing) {
      return false
    }

    return (
      <>
        <h6 className="uppercase -ml-5 text-sm">{t('ongoing')}</h6>
        {competitionsList(competitionsToDisplay.ongoing)}
      </>
    )
  }

  const completedCompetitions = () => {
    if (!competitionsToDisplay.completed) {
      return false
    }

    return (
      <>
        <h6
          className={`uppercase -ml-5 text-sm ${
            ongoingCompetitions() ? 'mt-6' : ''
          }`}
        >
          {t('completed')}
        </h6>
        {competitionsList(competitionsToDisplay.completed)}
      </>
    )
  }

  const nationalCompetitions = () => {
    if (!competitionsToDisplay) {
      return false
    }
    if (!ongoingCompetitions() && !completedCompetitions()) {
      return false
    }

    return (
      <div>
        {ongoingCompetitions()}
        {completedCompetitions()}
      </div>
    )
  }

  return (
    <>
      <SectionTitle>{t('National competitions')}</SectionTitle>
      <ul className="grid grid-rows-12 grid-cols-3 lg:grid-cols-8">
        {mappedCountriesToComponents}
      </ul>
      <LazyMotion features={domAnimation}>
        <m.div
          variants={animationVariants}
          initial={isVisible ? 'show' : 'hidden'}
          animate={isVisible ? 'show' : 'hidden'}
          transition={{ duration: 0.25 }}
          className=""
        >
          <StyledNationalCompetitionsBox
            ref={popupRef}
            className="fixed z-30 pt-12 pb-12 pl-4 pr-7 bg-deepDarkBlue"
          >
            <div className="overflow-auto">
              <button
                className="absolute top-4 right-5 text-white"
                type="button"
                onClick={() => setIsVisible(false)}
              >
                <CloseIcon
                  aria-label="Close Icon"
                  className="w-5 text-white stroke-current"
                />
              </button>
              <div className="pt-12">
                <div
                  className="px-8 text-white inset-0 overflow-auto"
                  style={{ maxHeight: '60vh' }}
                >
                  <ul className="w-full list-disc pl-3">
                    {nationalCompetitions()}
                  </ul>
                </div>
              </div>
            </div>
          </StyledNationalCompetitionsBox>
        </m.div>
      </LazyMotion>
    </>
  )
}

export default Countries
