import React, { FC } from 'react'
import SectionTitle from 'src/components/typography/SectionTitle'
import {
  IOrganization,
  IInternationalCompetition,
} from 'src/components/laws/ParticipatingCompetitions'

interface IInternationalCompetitionsProps {
  selectedOrganization: IOrganization
}

const Competition = ({ logoUrl, name }: IInternationalCompetition) => (
  <li
    key={name}
    className="flex flex-wrap justifyitems-center justify-center mb-6"
  >
    <img src={logoUrl} alt={name} className="w-12 h-12" />
    <p className="whitespace-pre text-center w-full mt-1">{name}</p>
  </li>
)

const InternationalCompetitions: FC<IInternationalCompetitionsProps> = ({
  selectedOrganization,
}) => {
  if (!selectedOrganization) {
    return <></>
  }

  const competitons = selectedOrganization.internationalCompetitions

  if (!competitons) {
    return <></>
  }

  const mappedCompetitionsToComponents = competitons.map((competition) =>
    Competition(competition)
  )

  return (
    <>
      <SectionTitle>
        {selectedOrganization.internationalCompetitionsLabel}
      </SectionTitle>
      <ul className="grid grid-rows-12 grid-cols-3 lg:grid-cols-6">
        {mappedCompetitionsToComponents}
      </ul>
    </>
  )
}

export default InternationalCompetitions
